/* eslint-disable array-callback-return */
/* eslint-disable no-tabs */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import '../assets/css/common.css'
import './ofertas-do-dia-template.scss'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// import SectionLojaProxima from '../components/SectionLojaProxima'
import { Helmet } from 'react-helmet'

const Banner = ({ bannerDelivery, bannerDeliveryMobile, pageContext }) => {
  return (
    <>
      <div className="d-lg-block d-none">
        <GatsbyImage
          image={bannerDelivery.childImageSharp.gatsbyImageData}
          alt={`${pageContext.page}`}
          className="art-directed"
        />
      </div>
      <div className="d-lg-none d-block">
        <GatsbyImage
          image={bannerDeliveryMobile.childImageSharp.gatsbyImageData}
          alt={`${pageContext.page}`}
          className="art-directed"
        />
      </div>
    </>
  )
}

const OfertasDoDia = ({ data, pageContext }) => {
  const ofertas = []
  data.Ofertas.nodes.map((tabloide) => {
    tabloide.offer.map((off) => {
      if (off.tag.indexOf(pageContext.semana) !== -1) {
        ofertas.push(off)
      }
    })
  })

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{pageContext.title} | Drogaleste</title>
        <meta name="title" content={`${pageContext.title} | Drogaleste`} />
        <meta name="description" content={`${pageContext.title} | Drogaleste`} />
        <meta name="keywords" content='ofertas-do-dia,dia,ofertas,drogaleste' />
        <meta name="author" content="Drogaleste" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pageContext.title} | Drogaleste`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content= 'https://www.drogaleste.com.br'/>
        <meta property="og:image" content={`https://www.drogaleste.com.br/ofertas-do-dia/card_share_${pageContext.page}.png`} />
        <meta property="og:description" content={`${pageContext.title} | Drogaleste`} />
      </Helmet>
      {/* <SectionLojaProxima /> */}
      <div className="container-fluid p-0">
        <Banner bannerDelivery={data[`${pageContext.page}`]} bannerDeliveryMobile={data[`${pageContext.page}mobile`]} pageContext={pageContext} />
      </div>
      <div className={`container-fluid bg-${pageContext.page}`}>
        <div className="w-100 text-center">
          <h2 className="text-ofertas" style={{ color: '#20438c' }}>Confira as categorias participantes<br /> da campanha e não perca as <b className="text-ofertas-bold">ofertas do dia.</b></h2>
        </div>
        <div className="container">
          <div className="row no-gutters seven-cols">
            <div className="col-lg-1 col-6">
              <Link to="/segundasenior/">
                <GatsbyImage
                  image={data.card1.childImageSharp.gatsbyImageData}
                  alt='card1'
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/tercafit/">
                <GatsbyImage
                  image={data.card2.childImageSharp.gatsbyImageData}
                  alt='card2'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/quartamedicamentos/">
                <GatsbyImage
                  image={data.card3.childImageSharp.gatsbyImageData}
                  alt='card3'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/quintadafarmacinha/">
                <GatsbyImage
                  image={data.card4.childImageSharp.gatsbyImageData}
                  alt='card4'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/sextadabeleza/">
                <GatsbyImage
                  image={data.card5.childImageSharp.gatsbyImageData}
                  alt='card5'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 col-6">
              <Link to="/sabadodocuidadopessoal/">
                <GatsbyImage
                  image={data.card6.childImageSharp.gatsbyImageData}
                  alt='card6'
                  className=''
                />
              </Link>
            </div>
            <div className="col-lg-1 d-lg-block d-none">
              <Link to="/domingokids/">
                <GatsbyImage
                  image={data.card7.childImageSharp.gatsbyImageData}
                  alt='card7'
                  className=''
                />
              </Link>
            </div>
            <div className="col-12 d-lg-none d-block">
              <Link to="/domingokids/">
                <GatsbyImage
                  image={data.card7mob.childImageSharp.gatsbyImageData}
                  alt='card7'
                  className=''
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-ofertasdia py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p><b className="text-white">Ofertas válidas na compra de até 5 peças de cada produto<br /> por cliente, até o término dos nossos estoques.</b></p>
              <p className="text-white mb-0">Encontre uma loja perto de você: <Link to="/lojas/" className="text-white"><b>https://www.drogaleste.com.br/lojas/</b></Link><br />Fale conosco: <Link to="/contato/" className="text-white"><b>https://www.drogaleste.com.br/contato/</b></Link></p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default OfertasDoDia
export const query = graphql`
  query {
    Ofertas: allCloudOfertasTabloide(
      filter: {tariff: {name: {eq: "OFERTAS-DO-DIA"}}}
    ) {
      nodes {
        offer {
          category {
            name
            id
          }
          startDate
          finishDate
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          ownBrand
          specialOffers
          order
          format
          id
          tag
        }
        legalText
        finishDate
        startDate
        status
      }
    }
    segundasenior: file(relativePath: {eq: "images/ofertasdodia/banner-segunda.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    tercafit: file(relativePath: {eq: "images/ofertasdodia/banner-terca.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    quartamedicamentos: file(relativePath: {eq: "images/ofertasdodia/quartamedicamentos.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    quintadafarmacinha: file(relativePath: {eq: "images/ofertasdodia/quintafarmacinha.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    sextadabeleza: file(relativePath: {eq: "images/ofertasdodia/sextadabeleza.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    sabadodocuidadopessoal: file(relativePath: {eq: "images/ofertasdodia/sabadodocuidadopessoal.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    domingokids: file(relativePath: {eq: "images/ofertasdodia/domingokids.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    segundaseniormobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-1.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    tercafitmobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-2.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    quartamedicamentosmobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-3.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    quintadafarmacinhamobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-4.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    sextadabelezamobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-5.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    sabadodocuidadopessoalmobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-6.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    domingokidsmobile: file(relativePath: {eq: "images/ofertasdodia/banner-mobile-7.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH )
      }
    }
    card1: file(relativePath: {eq: "images/ofertasdodia/card-01.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card2: file(relativePath: {eq: "images/ofertasdodia/card-02.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card3: file(relativePath: {eq: "images/ofertasdodia/card-03.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card4: file(relativePath: {eq: "images/ofertasdodia/card-04.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card5: file(relativePath: {eq: "images/ofertasdodia/card-05.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card6: file(relativePath: {eq: "images/ofertasdodia/card-06.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card7: file(relativePath: {eq: "images/ofertasdodia/card-07.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    card7mob: file(relativePath: {eq: "images/ofertasdodia/card-domingo-mob.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    drogaleste: file(relativePath: {eq: "images/ofertasdodia/drogaleste.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 162, height: 34 )
      }
    }
    selo: file(relativePath: {eq: "images/ofertasdodia/selo.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 378, height: 408 )
      }
    }
    bolinhas: file(relativePath: {eq: "images/ofertasdodia/bolinhas-maior.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 384, height: 381 )
      }
    }
    ondas: file(relativePath: {eq: "images/ofertasdodia/ondas.png"}){
      childImageSharp {
        gatsbyImageData(quality: 100, width: 1920, height: 396 )
      }
    }
  }
`
